.ScheduleModalBG {
  position: fixed;
  top: 0;right: 0;bottom: 0;left: 0;
  background-color: #0005;

  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;

  .ScheduleModal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    
    min-height: 550px;
    width: min(70%, 700px);
  
    border-radius: 25px;
    background-color: #fff;
    padding: 40px;

    @media screen and (max-width: 880px) {
      width: min(95%, 700px);
      max-width: 400px;
      min-height: 500px;
      padding: 40px 15px;
    }

    .h3 {
      text-align: center;
      font-weight: bold;
      padding: 0 50px; 
    }

    .xIcon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 24px;
      cursor: pointer;
    }

    .sActions {
      display: flex;
      gap: 20px;
    }
  }
}

.SelectorArea {
  display: flex;
  justify-content: center;align-items: center;
  flex-wrap: wrap;
  
  width: 100%;
  gap: 10px;

  .divisor {
    height: 100%;width: 1px;
    background-color: #0003;
  }

  .hourPicker {
    display: flex;
    justify-content: center;
    
    width: 140px;
    flex-shrink: 0;
    align-self: flex-start;

    text-align: center;
  }

}

.--small {}

.p-calendar {
  width: 100%;

  .p-datepicker {
    width: 100% !important;
  
    
    .p-datepicker-title .p-link {
      pointer-events: none !important;
    }
    
    table {
      font-size: 10px !important;
    }
    span {
      font-size: 12px !important;
      height: 1.5rem !important;
    }
  }
}
.p-disabled {
  opacity: .15 !important;
}

.p-dropdown {
  width: 100% !important; 
}

.p-link {
  font-size: 14px !important;
}