// $primary-color: #0F70D4;
$primary-color: #0F70D4;
// $primary-color: #0464ea;
// $primary-color: #39C641;


* {
  margin: 0;padding: 0;
  box-sizing: border-box;
  line-height: 1;
  // font-family: 'Montserrat', sans-serif;
  font-family: 'Archivo', sans-serif;
}

body {
  font-size: 62.5%;
  background-color: #fff;
}

a {
  text-decoration: none;
}

.h1 {
  font-size: clamp(2.6rem, 5vw, 3.2rem);
  font-weight: 700;
  color: #f5f5f5;
  line-height: 3rem;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #f5f5f5;
  line-height: 2rem;
}

.h3 {
  font-size: clamp(20px, 2vw, 24px);
  font-weight: 500;
  
  color: #f5f5f5;
  line-height: clamp(1.7rem, 3vw, 2rem);
}

.h4 {
  font-size: clamp(14px, 2vw, 18px);
  font-weight: 500;
  
  color: #ccc;
  line-height: clamp(1.1rem, 3vw, 1.5rem);
}