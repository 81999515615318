@import "./Globals.scss";

.ilustration {
  border-radius: 15px 95px 15px 15px;
  width: min(100%, 350px);
  max-height: 298px;
  object-fit: cover;

  @media screen and (max-width: 700px) {
    border-radius: 25px !important;
  }
}

.step-wrap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
  margin-bottom: 50px;
  width: min(515px, 85%);
  min-height: calc(100vh - 80px);
  
  @media screen and (max-width: 1000px) {
    margin-top: 50px;
    margin-bottom: 0;
  }
}

.step-container {
  display: flex;
  flex-direction: column;
  padding: 40px 40px;
  background-color: #282828;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  border-radius: 25px;
}

.step-btn {
  display: grid;
  place-items: center;
  
  margin: 24px;
  width: 56px;
  height: 56px;
  
  border-radius: 50%;
  background-color: #282828;

  .sbi {
    font-size: 14px;
    font-weight: 700;
    color: #0F70D4;
  }
}

.radio_group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;

  width: 100%;
  padding: 16px 26px;

  @media screen and (max-width: 1000px) {
    padding: 16px 0;
  }
}

.radio_field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  
  width: 100%;
  padding: 10px 16px;

  border-radius: 8px;
  border: 1px solid #f2f4f8;
  
  cursor: pointer;

  label {
    font-size: clamp(15px,1.5vw, 17px);
    color: #e5e5e5;
  }

  .p-radiobutton-box {
    background: transparent !important;
  }
}