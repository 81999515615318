@import "../../Styles/Globals.scss";

.CTAScreen {
  .step-container {
    align-items: center;
  }
  

  h1, h2, h3, h4 {
    text-align: center;
  }

  .mapContainer {
    width: min(100%, 400px);
    height: 400px;
    
    border-radius: 15px;
    overflow: hidden;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 1000px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;

      .cta {
        &:not(.cta2) {
          margin-left: 0 !important;
        }
      }
    }

  }
  
  @media screen and (max-width: 1000px) {
    .mobileDivisor {
      height: 20px;
    }
  }
}

.cta {
  padding: 10px 25px;
  line-height: 1;

  background-color: $primary-color;
  border-radius: 50px;
  border: 2px solid transparent;

  font-size: clamp(16px, 1.8vw, 20px);
  font-weight: 500;
  
  color: white;
  line-height: 1;
  cursor: pointer;

  &:not(.cta2) {
    margin-left: auto;
  }

  &.cta2 {
    background-color: transparent;
    border-color: $primary-color;
    color: #323C61;
  }

  &.--small {
    font-size: clamp(14px, 1.6vw, 16px);
  }

  &.disabled {
    opacity: .3;
    pointer-events: none;
    cursor: default;
  }
}