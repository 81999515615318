.layout {
  position: relative;
  
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  min-height: 100vh;
  width: 100%;

  background-color: #FCFCFC;
  background-image: url("../img/office.jpg");
  background-position: center;
  background-size: cover;
  
  
  .bg-overlay {
    position: absolute;
    top: 0;left: 0;right: 0;bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(252 252 252 / 80%);
    z-index: 1;
  }

  > *:not(.bg-overlay) {
    z-index: 10;
  }
  
  .Header {
    display: grid;
    place-items: center;

    height: 100px;
    width: 100px;
    
    background-color: #282828;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.12);
    border-radius: 0 0 25px 0;
    
    img {
      height: 50px;
      cursor: pointer;
    }
  }

  .App {
    display: flex;
    justify-content: center;
    align-items: center;
    
    .mobile-actions {
      display: none;
    }
    
    @media screen and (max-width: 1000px) {
      flex-direction: column;
      min-height: calc(100vh - 80px);

      .step-container, .step-wrap-container {
        min-height: unset;
      }
      
      .step-btn {
        display: none;
      }

      .mobile-actions {
        display: flex;
        
        .step-btn {
          display: grid;
        }
      }
    }
  }
}